import store from 'store';

const USER = 'USER';
const ACCESS_TOKEN = 'ACCESS_TOKEN';
const REFRESH_TOKEN = 'REFRESH_TOKEN';

export const getUser = () => {
	return store.get(USER);
};

export const getAccessToken = () => {
	return store.get(ACCESS_TOKEN);
};

export const getRefreshToken = () => {
	return store.get(REFRESH_TOKEN);
};

export const storeUser = (user) => {
	if (user) {
		store.set(USER, user);
	}
	return user;
};

export const storeAccessToken = (accessToken) => {
	if (accessToken) {
		store.set(ACCESS_TOKEN, accessToken);
	}
	return accessToken;
};

export const storeRefreshToken = (refreshToken) => {
	if (refreshToken) {
		store.set(REFRESH_TOKEN, refreshToken);
	}
	return refreshToken;
};

export const clearTokens = () => {
	store.remove(USER);
	store.remove(ACCESS_TOKEN);
	store.remove(REFRESH_TOKEN);
};
