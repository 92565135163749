const ApiConfig = {
	Auth: {
		login: 'auth/login',
		logout: 'auth/logout',
	},
	Dashboard: {
		stat: 'dashboard/stat',
	},
	Users: {
		list: 'users?role=User&sortBy=createdAt:desc',
		download: 'users/download?role=User&sortBy=createdAt:desc',
	},
	Blogs: {
		list: 'blogs?sortBy=createdAt:desc',
		create: 'blogs',
		update: 'blogs/',
		remove: 'blogs/',
	},
	Faqs: {
		list: 'faqs?sortBy=order:1',
		create: 'faqs',
		update: 'faqs/',
		remove: 'faqs/',
	},
	Content: 'content/',
	NewsletterSubscriptions: {
		list: 'newsletterSubscriptions?sortBy=createdAt:desc',
		download: 'newsletterSubscriptions/download?sortBy=createdAt:desc',
	},
	Staffs: {
		list: 'users?role=Staff&sortBy=createdAt:desc',
		create: 'users',
		update: 'users/',
		remove: 'users/',
	},
};

export default ApiConfig;
