import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import useAuth from '../../hooks/useAuth';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../components/bootstrap/Modal';
import useApi from '../../hooks/useApi';
import ApiConfig from '../../config/apiConfig';
import Spinner from '../../components/bootstrap/Spinner';
import { authPages } from '../../menu';
import UserImageWebp from '../../assets/img/wanna/wanna1.webp';
import UserImage from '../../assets/img/wanna/wanna1.png';

export const UserAvatar = ({ srcSet, src }) => {
	return (
		<div className='user-avatar'>
			<img srcSet={srcSet} src={src} alt='Avatar' width={128} height={128} />
		</div>
	);
};
UserAvatar.propTypes = {
	src: PropTypes.string.isRequired,
	srcSet: PropTypes.string,
};
UserAvatar.defaultProps = {
	srcSet: null,
};

const User = () => {
	const [displayLogoutModal, setDisplayLogoutModal] = useState(false);

	const [fetching, fetch] = useApi();
	const navigate = useNavigate();
	const { user, refreshToken, logout } = useAuth();

	const onLogout = async () => {
		try {
			await fetch({
				url: ApiConfig.Auth.logout,
				method: 'DELETE',
				data: { refreshToken },
			});
			logout();
			navigate(`/${authPages.login.path}`);
		} catch (error) {
			return false;
		}
		return true;
	};

	if (!user) return null;

	return (
		<>
			<Dropdown>
				<DropdownToggle hasIcon={false}>
					<div className='user'>
						<UserAvatar srcSet={UserImageWebp} src={UserImage} />
						<div className='user-info'>
							<div className='user-name'>{`${user.firstName} ${user.lastName}`}</div>
							<div className='user-sub-title'>{user.role}</div>
						</div>
					</div>
				</DropdownToggle>
				<DropdownMenu>
					<DropdownItem>
						<Button icon='Logout' onClick={() => setDisplayLogoutModal(true)}>
							Logout
						</Button>
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
			<Modal
				id='logoutModal'
				isOpen={displayLogoutModal}
				setIsOpen={setDisplayLogoutModal}
				isStaticBackdrop={fetching}>
				<ModalHeader setIsOpen={setDisplayLogoutModal}>
					<ModalTitle id='logoutModal'>Logout</ModalTitle>
				</ModalHeader>
				<ModalBody>Are you sure you want to logout?</ModalBody>
				<ModalFooter>
					<Button
						color='info'
						isOutline
						className='border-0'
						onClick={() => setDisplayLogoutModal(false)}
						isDisable={fetching}>
						No
					</Button>
					<Button color='info' onClick={onLogout} isDisable={fetching}>
						{fetching && <Spinner inButton isSmall />}
						Yes, Go ahead
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default User;
