import axios from 'axios';
import { clearTokens, getAccessToken } from '../storage/auth';

const apiClient = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

apiClient.interceptors.request.use((request) => {
	request.params = request.params || {};
	const accessToken = getAccessToken();
	if (accessToken) {
		request.headers.Authorization = `Bearer ${accessToken}`;
	}
	return request;
});

apiClient.interceptors.response.use(undefined, (error) => {
	const { response } = error;
	if (response?.status === 401) {
		clearTokens();
		window.location.href = '/account/login';
	}
	error.data = response?.data ?? {};
	return Promise.reject(error);
});

export default apiClient;
