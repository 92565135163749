import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, { CardBody } from '../../components/bootstrap/Card';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import Button from '../../components/bootstrap/Button';
import Logo from '../../components/Logo';
import useDarkMode from '../../hooks/useDarkMode';
import useApi from '../../hooks/useApi';
import ApiConfig from '../../config/apiConfig';
import Spinner from '../../components/bootstrap/Spinner';
import useAuth from '../../hooks/useAuth';

const loginFormKeys = {
	role: 'role',
	username: 'username',
	password: 'password',
};

const loginValidationSchema = Yup.object({
	[loginFormKeys.username]: Yup.string().required().email().label('Email'),
	[loginFormKeys.password]: Yup.string().required().min(8).max(32).label('Password'),
});

const LoginPage = () => {
	const { darkModeStatus } = useDarkMode();

	const [fetching, fetch] = useApi();
	const auth = useAuth();
	const navigate = useNavigate();
	const formik = useFormik({
		initialValues: {
			username: '',
			password: '',
		},
		validationSchema: loginValidationSchema,
		onSubmit: async (values) => {
			try {
				const { user, tokens } = await fetch({
					url: ApiConfig.Auth.login,
					method: 'POST',
					data: { role: 'Admin', ...values },
				});
				auth.login(user, tokens);
				navigate('/');
			} catch (error) {
				return false;
			}
			return true;
		},
	});

	return (
		<PageWrapper title='LoginPage' className={classNames('bg-info')}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo width={200} />
									</Link>
								</div>
								<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
								<div className='text-center h4 text-muted mb-5'>
									Sign in to continue!
								</div>
								<form className='row g-4' noValidate onSubmit={formik.handleSubmit}>
									<div className='col-12'>
										<FormGroup id='login-username' isFloating label='Email'>
											<Input
												name={loginFormKeys.username}
												autoComplete='email'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.username}
												isValid={formik.isValid}
												isTouched={formik.touched.username}
												invalidFeedback={formik.errors.username}
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<FormGroup id='login-password' isFloating label='Password'>
											<Input
												name={loginFormKeys.password}
												type='password'
												autoComplete='password'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.password}
												isValid={formik.isValid}
												isTouched={formik.touched.password}
												invalidFeedback={formik.errors.password}
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<Button
											type='submit'
											color='info'
											className='w-100 py-3'
											isDisable={fetching}>
											{fetching && <Spinner inButton isSmall />}
											Login
										</Button>
									</div>
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default LoginPage;
