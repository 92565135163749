import React, { lazy } from 'react';
import { dashboardMenu, authPages, mainMenu } from '../menu';
import LoginPage from '../pages/auth/LoginPage';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
};
const USER = {
	LIST: lazy(() => import('../pages/user/UserListPage')),
};
const BLOG = {
	LIST: lazy(() => import('../pages/blog/BlogListPage')),
};
const FAQ = {
	LIST: lazy(() => import('../pages/faq/FaqListPage')),
};
const CONTENT = lazy(() => import('../pages/content/ContentPage'));
const NEWSLETTER_SUBSCRIPTION = {
	LIST: lazy(() => import('../pages/newsletter-subscription/NewsletterSubscriptionListPage')),
};
const STAFF = {
	LIST: lazy(() => import('../pages/staff/StaffListPage')),
};
const AUTH = {
	PAGE_404: lazy(() => import('../pages/auth/Page404')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	{
		path: authPages.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: authPages.login.path,
		element: <LoginPage />,
		exact: true,
	},

	/** ************************************************** */

	/**
	 * Main pages
	 */
	{
		path: mainMenu.users.path,
		element: <USER.LIST />,
		exact: true,
	},
	{
		path: mainMenu.blogs.path,
		element: <BLOG.LIST />,
		exact: true,
	},
	{
		path: mainMenu.faqs.path,
		element: <FAQ.LIST />,
		exact: true,
	},
	{
		path: mainMenu.content.path,
		element: <CONTENT />,
		exact: true,
	},
	{
		path: mainMenu.newsletterSubscriptions.path,
		element: <NEWSLETTER_SUBSCRIPTION.LIST />,
		exact: true,
	},
	{
		path: mainMenu.staffs.path,
		element: <STAFF.LIST />,
		exact: true,
	},
];
const contents = [...presentation];

export default contents;
