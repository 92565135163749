import { useContext } from 'react';
import AuthContext from '../contexts/authContext';

export default function useAuth() {
	const { user, accessToken, refreshToken, login, logout } = useContext(AuthContext);

	const isLoggedIn = !!accessToken;

	return { user, isLoggedIn, accessToken, refreshToken, login, logout };
}
