import { useEffect, useState, useRef, useCallback } from 'react';
import { CancelToken, isCancel } from 'axios';
import apiClient from '../services/apiClient';
import showNotification from '../components/extras/showNotification';

const showErrorNotification = (message = 'Oops, something went wrong!') => {
	showNotification('Error', message, 'danger');
};

const useApi = () => {
	const [fetching, setFetching] = useState(false);
	const cancel = useRef();

	useEffect(() => {
		// cancel request on unmount
		return () => {
			if (cancel?.current) {
				cancel.current();
			}
		};
	}, []);

	const fetch = useCallback(
		async (config = {}, canDisplaySuccess = true, canDisplayError = true) => {
			if (cancel?.current) {
				// cancel old request
				cancel.current();
			}
			setFetching(true);
			return apiClient({
				...config,
				// create new cancel token
				cancelToken: new CancelToken((c) => {
					cancel.current = c;
				}),
			})
				.then((res) => {
					setFetching(false);
					if (canDisplaySuccess && res.data.message) {
						showNotification(res.data.message, null, 'success');
					}
					return res.data;
				})
				.catch((error) => {
					if (isCancel(error)) {
						error.isCancelled = true;
						throw error;
					} else {
						// handle error
						setFetching(false);
						if (canDisplayError) {
							showErrorNotification(error?.response?.data?.message);
						}
						throw error;
					}
				});
		},
		[],
	);

	return [fetching, fetch];
};

export default useApi;
