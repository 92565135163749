import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
	clearTokens,
	getAccessToken,
	getRefreshToken,
	getUser,
	storeAccessToken,
	storeRefreshToken,
	storeUser,
} from '../storage/auth';

const AuthContext = createContext(null);

export const AuthContextProvider = ({ children }) => {
	const [user, setUser] = useState(getUser);
	const [accessToken, setAccessToken] = useState(getAccessToken);
	const [refreshToken, setRefreshToken] = useState(getRefreshToken);

	const login = (userData, tokens) => {
		const { access, refresh } = tokens;
		setUser(storeUser(userData));
		setAccessToken(storeAccessToken(access.token));
		setRefreshToken(storeRefreshToken(refresh.token));
	};

	const logout = () => {
		clearTokens();
		setUser(null);
		setAccessToken(null);
		setRefreshToken(null);
	};

	const values = useMemo(
		() => ({ user, accessToken, refreshToken, login, logout }),
		[user, accessToken, refreshToken],
	);

	return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
