export const dashboardMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
};

export const mainMenu = {
	users: {
		id: 'users',
		text: 'Users',
		path: 'users/list',
		icon: 'People',
	},
	blogs: {
		id: 'blogs',
		text: 'Blogs',
		path: 'blogs/list',
		icon: 'Article',
	},
	faqs: {
		id: 'faqs',
		text: 'FAQs',
		path: 'faqs/list',
		icon: 'Info',
	},
	content: {
		id: 'content',
		text: 'Website Content',
		path: 'content',
		icon: 'Web',
	},
	newsletterSubscriptions: {
		id: 'newsletterSubscriptions',
		text: 'Newsletter Subscriptions',
		path: 'newsletter-subscriptions/list',
		icon: 'Newspaper',
	},
	staffs: {
		id: 'staffs',
		text: 'Staffs',
		path: 'staffs/list',
		icon: 'Groups',
	},
};

export const authPages = {
	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	login: {
		id: 'login',
		text: 'Login',
		path: 'auth/login',
		icon: 'Login',
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth/404',
		icon: 'ReportGmailerrorred',
	},
};
